//METRONIC
/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";
//FIM-METRONIC

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";
@import "./assets/famfamfam-flags/famfamfam-flags.css";

// Carousel
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
//Correções Styles após embutir o metronic

input {
  min-height: 36px;
}

.form-control:focus {
  border-color: #86b7fe !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #f1416c !important;
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    //color: $primary !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

.nav-pills .nav-item:last-child {
  margin-right: 0.5rem !important;
}

.btn,
.small-box {
  i.bi,
  i[class^="fonticon-"],
  i[class*=" fonticon-"],
  i[class^="fa-"],
  i[class*=" fa-"],
  i[class^="la-"],
  i[class*=" la-"] {
    //line-height: 1;
    //font-size: 1rem;
    color: #f5f8fa;
    color: var(--kt-text-light);
  }
}

.info-box-icon {
  i.bi,
  i[class^="fonticon-"],
  i[class*=" fonticon-"],
  i[class^="fa-"],
  i[class*=" fa-"],
  i[class^="la-"],
  i[class*=" la-"] {
    line-height: 1;
    font-size: 1.875rem;
    color: #f5f8fa;
    color: var(--kt-text-light);
  }
}

.card .card-header {
  min-height: 30px;
  padding: 1.25rem 2.25rem;
}

.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  border: 0;
  padding: calc(0.48rem + 1px) calc(1.5rem + 1px);
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
}
.btn.btn-light-secondary i {
  color: #3F4254;
}

.btn.btn-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-secondary:focus:not(.btn-text) i, .btn.btn-light-secondary.focus:not(.btn-text) i {
  color: #000;
}

.btn.btn-secondary {
  background-color: #003399 !important;
}

.page-item.active .page-link{
  background-color: #003399 !important;
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #039 !important;
}

.mat-slider.mat-primary .mat-slider-track-fill, .mat-slider.mat-primary .mat-slider-thumb, .mat-slider.mat-primary .mat-slider-thumb-label{
  background-color: #039 !important;
}
.mat-slider-thumb-label{
  width: 45px !important;
  height: 45px !important;
  top: -53px !important
}

.mat-slider-thumb-label-text{
  font-size: 10px !important;
  padding: 4px !important;
}

.modal-xlg {
  max-width: 80% !important;
}

.modal-full {
  min-width: 100% !important;
  min-height: 100% !important;
  border-radius: 0px !important;
  border: 0px !important;
}

.btn-green {
  background-color: $primary-light-green !important;
  color: $primary-customer-dark !important;
  font-weight: 500 !important;
}

.btn-green:hover{
  background-color: $primary-green-hover !important;
}

.btn-outline-primary{
  border: 1px solid $primary !important;
  color: $primary !important;
}

.btn-outline-primary:hover{
  border: none !important;
  color: white !important;
  background-color: $primary !important;
}