//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
$primary: #003399;
$table-hover-bg: #CDD6F1;
$primary-project: #003399;
$primary-medium-opacity: #00339969;
$primary-low-opacity: #0033992d;
$primary-project: #003399;
$primary-project-light: #CDD6F1;
$primary-active: #3e6ece;
$primary-active-dark: #009245;
$primary-dark: #3c3c3b;
$primary-focus: #35528d;
$primary-light-dark: #212e48;
$primary-inverse: $white;
$primary-light-green: #84D600;
$primary-green-hover: #B3E24D;
$primary-customer-dark: #001B63;
$primary-dark-blue: #000B36;
$primary-white-ice: #FDFDFD;
$header-active: #506F36;
$trash-active: #D35D6E;
$modal-color: #F6F6F8;
$white-soft: #EBEBEB;
$white-soft-2: #DCDEE3;
$cinzaColor: #ccc;
$form-border: #3e98c7;
$input-select-noResults: #999;
$form-groupColor: #333333;
$container-fluidBack: #f8f9fa;
$btn-secondaryHover: #357abd;
$secondary-white: #f7f7f7;
$form-group-span: #676F87;
$grey-light-claro: #c2c2c2;



// Success
$success: #50cdb2;
$success-active: #47beae;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #fcd95a;
$warning-active: #ffd026;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;
$error-message-card: #d9534f;

//Card
$card-background: #E8EFF6;
$card-text-color: #676F87;
$input-focus: #3e98c7;

//Company
$brandcolor-company-pure: #2E765E;
$brandcolor-company-dark: #174A39;
$brandcolor-company-lightest: #ECF3EF;
$neutralcolor-low-medium: #676F87;

$grid-breakpoints-custom: (
  xs: 321px,
  sm: 426px,
  md: 768px,
  l: 900px,
  lg: 1000px,
  xl: 1100px,
  xxl: 1200px,
  xxxl: 2560px
) !default;

//custom-fonts
$font-work-sans: "Work Sans";
$font-DM-Sans: "DM Sans";
