//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";


.tab-group .mat-tab-labels {
    display: block;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: #84D600;
}

.mat-tab-label:active {
    border-radius: 10px 10px 0 0;
}

.mat-tab-label.mat-tab-label-active {
    background-color: #ecffcd;
    font-weight: 700;
    border-radius: 10px 10px 0 0;
}