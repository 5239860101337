.famfamfam-flags {
  background: url("famfamfam-flags.png") no-repeat;
  background-size: 224px 199px;
}

.famfamfam-flags.me {
  width: 16px;
  height: 12px;
  background-position: 0px 0px;
}

.famfamfam-flags.ky {
  width: 16px;
  height: 11px;
  background-position: -16px 0px;
}

.famfamfam-flags.af {
  width: 16px;
  height: 11px;
  background-position: 0px -12px;
}

.famfamfam-flags.ag {
  width: 16px;
  height: 11px;
  background-position: -16px -12px;
}

.famfamfam-flags.ai {
  width: 16px;
  height: 11px;
  background-position: -32px 0px;
}

.famfamfam-flags.al {
  width: 16px;
  height: 11px;
  background-position: -32px -11px;
}

.famfamfam-flags.am {
  width: 16px;
  height: 11px;
  background-position: 0px -23px;
}

.famfamfam-flags.an {
  width: 16px;
  height: 11px;
  background-position: -16px -23px;
}

.famfamfam-flags.ao {
  width: 16px;
  height: 11px;
  background-position: -32px -23px;
}

.famfamfam-flags.ar {
  width: 16px;
  height: 11px;
  background-position: 0px -34px;
}

.famfamfam-flags.as {
  width: 16px;
  height: 11px;
  background-position: -16px -34px;
}

.famfamfam-flags.at {
  width: 16px;
  height: 11px;
  background-position: -32px -34px;
}

.famfamfam-flags.au {
  width: 16px;
  height: 11px;
  background-position: -48px 0px;
}

.famfamfam-flags.aw {
  width: 16px;
  height: 11px;
  background-position: -48px -11px;
}

.famfamfam-flags.ax {
  width: 16px;
  height: 11px;
  background-position: -48px -22px;
}

.famfamfam-flags.az {
  width: 16px;
  height: 11px;
  background-position: -48px -33px;
}

.famfamfam-flags.ba {
  width: 16px;
  height: 11px;
  background-position: 0px -45px;
}

.famfamfam-flags.bb {
  width: 16px;
  height: 11px;
  background-position: -16px -45px;
}

.famfamfam-flags.bd {
  width: 16px;
  height: 11px;
  background-position: -32px -45px;
}

.famfamfam-flags.be {
  width: 16px;
  height: 11px;
  background-position: -48px -45px;
}

.famfamfam-flags.bf {
  width: 16px;
  height: 11px;
  background-position: -64px 0px;
}

.famfamfam-flags.bg {
  width: 16px;
  height: 11px;
  background-position: -64px -11px;
}

.famfamfam-flags.bh {
  width: 16px;
  height: 11px;
  background-position: -64px -22px;
}

.famfamfam-flags.bi {
  width: 16px;
  height: 11px;
  background-position: -64px -33px;
}

.famfamfam-flags.bj {
  width: 16px;
  height: 11px;
  background-position: -64px -44px;
}

.famfamfam-flags.bm {
  width: 16px;
  height: 11px;
  background-position: 0px -56px;
}

.famfamfam-flags.bn {
  width: 16px;
  height: 11px;
  background-position: -16px -56px;
}

.famfamfam-flags.bo {
  width: 16px;
  height: 11px;
  background-position: -32px -56px;
}

.famfamfam-flags.br {
  width: 16px;
  height: 11px;
  background-position: -48px -56px;
}

.famfamfam-flags.bs {
  width: 16px;
  height: 11px;
  background-position: -64px -56px;
}

.famfamfam-flags.bt {
  width: 16px;
  height: 11px;
  background-position: 0px -67px;
}

.famfamfam-flags.bv {
  width: 16px;
  height: 11px;
  background-position: -16px -67px;
}

.famfamfam-flags.bw {
  width: 16px;
  height: 11px;
  background-position: -32px -67px;
}

.famfamfam-flags.by {
  width: 16px;
  height: 11px;
  background-position: -48px -67px;
}

.famfamfam-flags.bz {
  width: 16px;
  height: 11px;
  background-position: -64px -67px;
}

.famfamfam-flags.ca {
  width: 16px;
  height: 11px;
  background-position: -80px 0px;
}

.famfamfam-flags.catalonia {
  width: 16px;
  height: 11px;
  background-position: -80px -11px;
}

.famfamfam-flags.cc {
  width: 16px;
  height: 11px;
  background-position: -80px -22px;
}

.famfamfam-flags.cd {
  width: 16px;
  height: 11px;
  background-position: -80px -33px;
}

.famfamfam-flags.cf {
  width: 16px;
  height: 11px;
  background-position: -80px -44px;
}

.famfamfam-flags.cg {
  width: 16px;
  height: 11px;
  background-position: -80px -55px;
}

.famfamfam-flags.zm {
  width: 16px;
  height: 11px;
  background-position: -80px -66px;
}

.famfamfam-flags.ci {
  width: 16px;
  height: 11px;
  background-position: 0px -78px;
}

.famfamfam-flags.ck {
  width: 16px;
  height: 11px;
  background-position: -16px -78px;
}

.famfamfam-flags.cl {
  width: 16px;
  height: 11px;
  background-position: -32px -78px;
}

.famfamfam-flags.cm {
  width: 16px;
  height: 11px;
  background-position: -48px -78px;
}

.famfamfam-flags.cn {
  width: 16px;
  height: 11px;
  background-position: -64px -78px;
}

.famfamfam-flags.co {
  width: 16px;
  height: 11px;
  background-position: -80px -78px;
}

.famfamfam-flags.cr {
  width: 16px;
  height: 11px;
  background-position: -96px 0px;
}

.famfamfam-flags.cs {
  width: 16px;
  height: 11px;
  background-position: -96px -11px;
}

.famfamfam-flags.cu {
  width: 16px;
  height: 11px;
  background-position: -96px -22px;
}

.famfamfam-flags.cv {
  width: 16px;
  height: 11px;
  background-position: -96px -33px;
}

.famfamfam-flags.cx {
  width: 16px;
  height: 11px;
  background-position: -96px -44px;
}

.famfamfam-flags.cy {
  width: 16px;
  height: 11px;
  background-position: -96px -55px;
}

.famfamfam-flags.cz {
  width: 16px;
  height: 11px;
  background-position: -96px -66px;
}

.famfamfam-flags.de {
  width: 16px;
  height: 11px;
  background-position: -96px -77px;
}

.famfamfam-flags.dj {
  width: 16px;
  height: 11px;
  background-position: 0px -89px;
}

.famfamfam-flags.dk {
  width: 16px;
  height: 11px;
  background-position: -16px -89px;
}

.famfamfam-flags.dm {
  width: 16px;
  height: 11px;
  background-position: -32px -89px;
}

.famfamfam-flags.do {
  width: 16px;
  height: 11px;
  background-position: -48px -89px;
}

.famfamfam-flags.dz {
  width: 16px;
  height: 11px;
  background-position: -64px -89px;
}

.famfamfam-flags.ec {
  width: 16px;
  height: 11px;
  background-position: -80px -89px;
}

.famfamfam-flags.ee {
  width: 16px;
  height: 11px;
  background-position: -96px -89px;
}

.famfamfam-flags.eg {
  width: 16px;
  height: 11px;
  background-position: 0px -100px;
}

.famfamfam-flags.eh {
  width: 16px;
  height: 11px;
  background-position: -16px -100px;
}

.famfamfam-flags.england {
  width: 16px;
  height: 11px;
  background-position: -32px -100px;
}

.famfamfam-flags.er {
  width: 16px;
  height: 11px;
  background-position: -48px -100px;
}

.famfamfam-flags.es {
  width: 16px;
  height: 11px;
  background-position: -64px -100px;
}

.famfamfam-flags.et {
  width: 16px;
  height: 11px;
  background-position: -80px -100px;
}

.famfamfam-flags.europeanunion {
  width: 16px;
  height: 11px;
  background-position: -96px -100px;
}

.famfamfam-flags.fam {
  width: 16px;
  height: 11px;
  background-position: -112px 0px;
}

.famfamfam-flags.fi {
  width: 16px;
  height: 11px;
  background-position: -112px -11px;
}

.famfamfam-flags.fj {
  width: 16px;
  height: 11px;
  background-position: -112px -22px;
}

.famfamfam-flags.fk {
  width: 16px;
  height: 11px;
  background-position: -112px -33px;
}

.famfamfam-flags.fm {
  width: 16px;
  height: 11px;
  background-position: -112px -44px;
}

.famfamfam-flags.fo {
  width: 16px;
  height: 11px;
  background-position: -112px -55px;
}

.famfamfam-flags.gp, .famfamfam-flags.mf, .famfamfam-flags.re, .famfamfam-flags.yt, .famfamfam-flags.fr {
  width: 16px;
  height: 11px;
  background-position: -112px -66px;
}

.famfamfam-flags.ga {
  width: 16px;
  height: 11px;
  background-position: -112px -77px;
}

.famfamfam-flags.gb {
  width: 16px;
  height: 11px;
  background-position: -112px -88px;
}

.famfamfam-flags.gd {
  width: 16px;
  height: 11px;
  background-position: -112px -99px;
}

.famfamfam-flags.ge {
  width: 16px;
  height: 11px;
  background-position: 0px -111px;
}

.famfamfam-flags.gf {
  width: 16px;
  height: 11px;
  background-position: -16px -111px;
}

.famfamfam-flags.gg {
  width: 16px;
  height: 11px;
  background-position: -32px -111px;
}

.famfamfam-flags.gh {
  width: 16px;
  height: 11px;
  background-position: -48px -111px;
}

.famfamfam-flags.gi {
  width: 16px;
  height: 11px;
  background-position: -64px -111px;
}

.famfamfam-flags.gl {
  width: 16px;
  height: 11px;
  background-position: -80px -111px;
}

.famfamfam-flags.gm {
  width: 16px;
  height: 11px;
  background-position: -96px -111px;
}

.famfamfam-flags.gn {
  width: 16px;
  height: 11px;
  background-position: -112px -111px;
}

.famfamfam-flags.gp {
  width: 16px;
  height: 11px;
  background-position: -128px 0px;
}

.famfamfam-flags.gq {
  width: 16px;
  height: 11px;
  background-position: -128px -11px;
}

.famfamfam-flags.gr {
  width: 16px;
  height: 11px;
  background-position: -128px -22px;
}

.famfamfam-flags.gs {
  width: 16px;
  height: 11px;
  background-position: -128px -33px;
}

.famfamfam-flags.gt {
  width: 16px;
  height: 11px;
  background-position: -128px -44px;
}

.famfamfam-flags.gu {
  width: 16px;
  height: 11px;
  background-position: -128px -55px;
}

.famfamfam-flags.gw {
  width: 16px;
  height: 11px;
  background-position: -128px -66px;
}

.famfamfam-flags.gy {
  width: 16px;
  height: 11px;
  background-position: -128px -77px;
}

.famfamfam-flags.hk {
  width: 16px;
  height: 11px;
  background-position: -128px -88px;
}

.famfamfam-flags.hm {
  width: 16px;
  height: 11px;
  background-position: -128px -99px;
}

.famfamfam-flags.hn {
  width: 16px;
  height: 11px;
  background-position: -128px -110px;
}

.famfamfam-flags.hr {
  width: 16px;
  height: 11px;
  background-position: 0px -122px;
}

.famfamfam-flags.ht {
  width: 16px;
  height: 11px;
  background-position: -16px -122px;
}

.famfamfam-flags.hu {
  width: 16px;
  height: 11px;
  background-position: -32px -122px;
}

.famfamfam-flags.id {
  width: 16px;
  height: 11px;
  background-position: -48px -122px;
}

.famfamfam-flags.ie {
  width: 16px;
  height: 11px;
  background-position: -64px -122px;
}

.famfamfam-flags.il {
  width: 16px;
  height: 11px;
  background-position: -80px -122px;
}

.famfamfam-flags.in {
  width: 16px;
  height: 11px;
  background-position: -96px -122px;
}

.famfamfam-flags.io {
  width: 16px;
  height: 11px;
  background-position: -112px -122px;
}

.famfamfam-flags.iq {
  width: 16px;
  height: 11px;
  background-position: -128px -122px;
}

.famfamfam-flags.ir {
  width: 16px;
  height: 11px;
  background-position: 0px -133px;
}

.famfamfam-flags.is {
  width: 16px;
  height: 11px;
  background-position: -16px -133px;
}

.famfamfam-flags.it {
  width: 16px;
  height: 11px;
  background-position: -32px -133px;
}

.famfamfam-flags.je {
  width: 16px;
  height: 11px;
  background-position: -48px -133px;
}

.famfamfam-flags.jm {
  width: 16px;
  height: 11px;
  background-position: -64px -133px;
}

.famfamfam-flags.jo {
  width: 16px;
  height: 11px;
  background-position: -80px -133px;
}

.famfamfam-flags.jp {
  width: 16px;
  height: 11px;
  background-position: -96px -133px;
}

.famfamfam-flags.ke {
  width: 16px;
  height: 11px;
  background-position: -112px -133px;
}

.famfamfam-flags.kg {
  width: 16px;
  height: 11px;
  background-position: -128px -133px;
}

.famfamfam-flags.kh {
  width: 16px;
  height: 11px;
  background-position: -144px 0px;
}

.famfamfam-flags.ki {
  width: 16px;
  height: 11px;
  background-position: -144px -11px;
}

.famfamfam-flags.km {
  width: 16px;
  height: 11px;
  background-position: -144px -22px;
}

.famfamfam-flags.kn {
  width: 16px;
  height: 11px;
  background-position: -144px -33px;
}

.famfamfam-flags.kp {
  width: 16px;
  height: 11px;
  background-position: -144px -44px;
}

.famfamfam-flags.kr {
  width: 16px;
  height: 11px;
  background-position: -144px -55px;
}

.famfamfam-flags.kw {
  width: 16px;
  height: 11px;
  background-position: -144px -66px;
}

.famfamfam-flags.ae {
  width: 16px;
  height: 11px;
  background-position: -144px -77px;
}

.famfamfam-flags.kz {
  width: 16px;
  height: 11px;
  background-position: -144px -88px;
}

.famfamfam-flags.la {
  width: 16px;
  height: 11px;
  background-position: -144px -99px;
}

.famfamfam-flags.lb {
  width: 16px;
  height: 11px;
  background-position: -144px -110px;
}

.famfamfam-flags.lc {
  width: 16px;
  height: 11px;
  background-position: -144px -121px;
}

.famfamfam-flags.li {
  width: 16px;
  height: 11px;
  background-position: -144px -132px;
}

.famfamfam-flags.lk {
  width: 16px;
  height: 11px;
  background-position: 0px -144px;
}

.famfamfam-flags.lr {
  width: 16px;
  height: 11px;
  background-position: -16px -144px;
}

.famfamfam-flags.ls {
  width: 16px;
  height: 11px;
  background-position: -32px -144px;
}

.famfamfam-flags.lt {
  width: 16px;
  height: 11px;
  background-position: -48px -144px;
}

.famfamfam-flags.lu {
  width: 16px;
  height: 11px;
  background-position: -64px -144px;
}

.famfamfam-flags.lv {
  width: 16px;
  height: 11px;
  background-position: -80px -144px;
}

.famfamfam-flags.ly {
  width: 16px;
  height: 11px;
  background-position: -96px -144px;
}

.famfamfam-flags.ma {
  width: 16px;
  height: 11px;
  background-position: -112px -144px;
}

.famfamfam-flags.mc {
  width: 16px;
  height: 11px;
  background-position: -128px -144px;
}

.famfamfam-flags.md {
  width: 16px;
  height: 11px;
  background-position: -144px -144px;
}

.famfamfam-flags.ad {
  width: 16px;
  height: 11px;
  background-position: -160px 0px;
}

.famfamfam-flags.mg {
  width: 16px;
  height: 11px;
  background-position: -160px -11px;
}

.famfamfam-flags.mh {
  width: 16px;
  height: 11px;
  background-position: -160px -22px;
}

.famfamfam-flags.mk {
  width: 16px;
  height: 11px;
  background-position: -160px -33px;
}

.famfamfam-flags.ml {
  width: 16px;
  height: 11px;
  background-position: -160px -44px;
}

.famfamfam-flags.mm {
  width: 16px;
  height: 11px;
  background-position: -160px -55px;
}

.famfamfam-flags.mn {
  width: 16px;
  height: 11px;
  background-position: -160px -66px;
}

.famfamfam-flags.mo {
  width: 16px;
  height: 11px;
  background-position: -160px -77px;
}

.famfamfam-flags.mp {
  width: 16px;
  height: 11px;
  background-position: -160px -88px;
}

.famfamfam-flags.mq {
  width: 16px;
  height: 11px;
  background-position: -160px -99px;
}

.famfamfam-flags.mr {
  width: 16px;
  height: 11px;
  background-position: -160px -110px;
}

.famfamfam-flags.ms {
  width: 16px;
  height: 11px;
  background-position: -160px -121px;
}

.famfamfam-flags.mt {
  width: 16px;
  height: 11px;
  background-position: -160px -132px;
}

.famfamfam-flags.mu {
  width: 16px;
  height: 11px;
  background-position: -160px -143px;
}

.famfamfam-flags.mv {
  width: 16px;
  height: 11px;
  background-position: 0px -155px;
}

.famfamfam-flags.mw {
  width: 16px;
  height: 11px;
  background-position: -16px -155px;
}

.famfamfam-flags.mx {
  width: 16px;
  height: 11px;
  background-position: -32px -155px;
}

.famfamfam-flags.my {
  width: 16px;
  height: 11px;
  background-position: -48px -155px;
}

.famfamfam-flags.mz {
  width: 16px;
  height: 11px;
  background-position: -64px -155px;
}

.famfamfam-flags.na {
  width: 16px;
  height: 11px;
  background-position: -80px -155px;
}

.famfamfam-flags.nc {
  width: 16px;
  height: 11px;
  background-position: -96px -155px;
}

.famfamfam-flags.ne {
  width: 16px;
  height: 11px;
  background-position: -112px -155px;
}

.famfamfam-flags.nf {
  width: 16px;
  height: 11px;
  background-position: -128px -155px;
}

.famfamfam-flags.ng {
  width: 16px;
  height: 11px;
  background-position: -144px -155px;
}

.famfamfam-flags.ni {
  width: 16px;
  height: 11px;
  background-position: -160px -155px;
}

.famfamfam-flags.bq, .famfamfam-flags.nl {
  width: 16px;
  height: 11px;
  background-position: -176px 0px;
}

.famfamfam-flags.no {
  width: 16px;
  height: 11px;
  background-position: -176px -11px;
}

.famfamfam-flags.za {
  width: 16px;
  height: 11px;
  background-position: -176px -22px;
}

.famfamfam-flags.nr {
  width: 16px;
  height: 11px;
  background-position: -176px -33px;
}

.famfamfam-flags.nu {
  width: 16px;
  height: 11px;
  background-position: -176px -44px;
}

.famfamfam-flags.nz {
  width: 16px;
  height: 11px;
  background-position: -176px -55px;
}

.famfamfam-flags.om {
  width: 16px;
  height: 11px;
  background-position: -176px -66px;
}

.famfamfam-flags.pa {
  width: 16px;
  height: 11px;
  background-position: -176px -77px;
}

.famfamfam-flags.pe {
  width: 16px;
  height: 11px;
  background-position: -176px -88px;
}

.famfamfam-flags.pf {
  width: 16px;
  height: 11px;
  background-position: -176px -99px;
}

.famfamfam-flags.pg {
  width: 16px;
  height: 11px;
  background-position: -176px -110px;
}

.famfamfam-flags.ph {
  width: 16px;
  height: 11px;
  background-position: -176px -121px;
}

.famfamfam-flags.pk {
  width: 16px;
  height: 11px;
  background-position: -176px -132px;
}

.famfamfam-flags.pl {
  width: 16px;
  height: 11px;
  background-position: -176px -143px;
}

.famfamfam-flags.pm {
  width: 16px;
  height: 11px;
  background-position: -176px -154px;
}

.famfamfam-flags.pn {
  width: 16px;
  height: 11px;
  background-position: 0px -166px;
}

.famfamfam-flags.pr {
  width: 16px;
  height: 11px;
  background-position: -16px -166px;
}

.famfamfam-flags.ps {
  width: 16px;
  height: 11px;
  background-position: -32px -166px;
}

.famfamfam-flags.pt {
  width: 16px;
  height: 11px;
  background-position: -48px -166px;
}

.famfamfam-flags.pw {
  width: 16px;
  height: 11px;
  background-position: -64px -166px;
}

.famfamfam-flags.py {
  width: 16px;
  height: 11px;
  background-position: -80px -166px;
}

.famfamfam-flags.qa {
  width: 16px;
  height: 11px;
  background-position: -96px -166px;
}

.famfamfam-flags.re {
  width: 16px;
  height: 11px;
  background-position: -112px -166px;
}

.famfamfam-flags.ro {
  width: 16px;
  height: 11px;
  background-position: -128px -166px;
}

.famfamfam-flags.rs {
  width: 16px;
  height: 11px;
  background-position: -144px -166px;
}

.famfamfam-flags.ru {
  width: 16px;
  height: 11px;
  background-position: -160px -166px;
}

.famfamfam-flags.rw {
  width: 16px;
  height: 11px;
  background-position: -176px -166px;
}

.famfamfam-flags.sa {
  width: 16px;
  height: 11px;
  background-position: 0px -177px;
}

.famfamfam-flags.sb {
  width: 16px;
  height: 11px;
  background-position: -16px -177px;
}

.famfamfam-flags.sc {
  width: 16px;
  height: 11px;
  background-position: -32px -177px;
}

.famfamfam-flags.scotland {
  width: 16px;
  height: 11px;
  background-position: -48px -177px;
}

.famfamfam-flags.sd {
  width: 16px;
  height: 11px;
  background-position: -64px -177px;
}

.famfamfam-flags.se {
  width: 16px;
  height: 11px;
  background-position: -80px -177px;
}

.famfamfam-flags.sg {
  width: 16px;
  height: 11px;
  background-position: -96px -177px;
}

.famfamfam-flags.sh {
  width: 16px;
  height: 11px;
  background-position: -112px -177px;
}

.famfamfam-flags.si {
  width: 16px;
  height: 11px;
  background-position: -128px -177px;
}

.famfamfam-flags.sj {
  width: 16px;
  height: 11px;
  background-position: -144px -177px;
}

.famfamfam-flags.sk {
  width: 16px;
  height: 11px;
  background-position: -160px -177px;
}

.famfamfam-flags.sl {
  width: 16px;
  height: 11px;
  background-position: -176px -177px;
}

.famfamfam-flags.sm {
  width: 16px;
  height: 11px;
  background-position: -192px 0px;
}

.famfamfam-flags.sn {
  width: 16px;
  height: 11px;
  background-position: -192px -11px;
}

.famfamfam-flags.so {
  width: 16px;
  height: 11px;
  background-position: -192px -22px;
}

.famfamfam-flags.sr {
  width: 16px;
  height: 11px;
  background-position: -192px -33px;
}

.famfamfam-flags.st {
  width: 16px;
  height: 11px;
  background-position: -192px -44px;
}

.famfamfam-flags.sv {
  width: 16px;
  height: 11px;
  background-position: -192px -55px;
}

.famfamfam-flags.sy {
  width: 16px;
  height: 11px;
  background-position: -192px -66px;
}

.famfamfam-flags.sz {
  width: 16px;
  height: 11px;
  background-position: -192px -77px;
}

.famfamfam-flags.tc {
  width: 16px;
  height: 11px;
  background-position: -192px -88px;
}

.famfamfam-flags.td {
  width: 16px;
  height: 11px;
  background-position: -192px -99px;
}

.famfamfam-flags.tf {
  width: 16px;
  height: 11px;
  background-position: -192px -110px;
}

.famfamfam-flags.tg {
  width: 16px;
  height: 11px;
  background-position: -192px -121px;
}

.famfamfam-flags.th {
  width: 16px;
  height: 11px;
  background-position: -192px -132px;
}

.famfamfam-flags.tj {
  width: 16px;
  height: 11px;
  background-position: -192px -143px;
}

.famfamfam-flags.tk {
  width: 16px;
  height: 11px;
  background-position: -192px -154px;
}

.famfamfam-flags.tl {
  width: 16px;
  height: 11px;
  background-position: -192px -165px;
}

.famfamfam-flags.tm {
  width: 16px;
  height: 11px;
  background-position: -192px -176px;
}

.famfamfam-flags.tn {
  width: 16px;
  height: 11px;
  background-position: 0px -188px;
}

.famfamfam-flags.to {
  width: 16px;
  height: 11px;
  background-position: -16px -188px;
}

.famfamfam-flags.tr {
  width: 16px;
  height: 11px;
  background-position: -32px -188px;
}

.famfamfam-flags.tt {
  width: 16px;
  height: 11px;
  background-position: -48px -188px;
}

.famfamfam-flags.tv {
  width: 16px;
  height: 11px;
  background-position: -64px -188px;
}

.famfamfam-flags.tw {
  width: 16px;
  height: 11px;
  background-position: -80px -188px;
}

.famfamfam-flags.tz {
  width: 16px;
  height: 11px;
  background-position: -96px -188px;
}

.famfamfam-flags.ua {
  width: 16px;
  height: 11px;
  background-position: -112px -188px;
}

.famfamfam-flags.ug {
  width: 16px;
  height: 11px;
  background-position: -128px -188px;
}

.famfamfam-flags.um {
  width: 16px;
  height: 11px;
  background-position: -144px -188px;
}

.famfamfam-flags.us {
  width: 16px;
  height: 11px;
  background-position: -160px -188px;
}

.famfamfam-flags.uy {
  width: 16px;
  height: 11px;
  background-position: -176px -188px;
}

.famfamfam-flags.uz {
  width: 16px;
  height: 11px;
  background-position: -192px -188px;
}

.famfamfam-flags.va {
  width: 16px;
  height: 11px;
  background-position: -208px 0px;
}

.famfamfam-flags.vc {
  width: 16px;
  height: 11px;
  background-position: -208px -11px;
}

.famfamfam-flags.ve {
  width: 16px;
  height: 11px;
  background-position: -208px -22px;
}

.famfamfam-flags.vg {
  width: 16px;
  height: 11px;
  background-position: -208px -33px;
}

.famfamfam-flags.vi {
  width: 16px;
  height: 11px;
  background-position: -208px -44px;
}

.famfamfam-flags.vn {
  width: 16px;
  height: 11px;
  background-position: -208px -55px;
}

.famfamfam-flags.vu {
  width: 16px;
  height: 11px;
  background-position: -208px -66px;
}

.famfamfam-flags.wales {
  width: 16px;
  height: 11px;
  background-position: -208px -77px;
}

.famfamfam-flags.wf {
  width: 16px;
  height: 11px;
  background-position: -208px -88px;
}

.famfamfam-flags.ws {
  width: 16px;
  height: 11px;
  background-position: -208px -99px;
}

.famfamfam-flags.ye {
  width: 16px;
  height: 11px;
  background-position: -208px -110px;
}

.famfamfam-flags.yt {
  width: 16px;
  height: 11px;
  background-position: -208px -121px;
}

.famfamfam-flags.zw {
  width: 16px;
  height: 11px;
  background-position: -208px -132px;
}

.famfamfam-flags.ch {
  width: 11px;
  height: 11px;
  background-position: -208px -143px;
}

.famfamfam-flags.np {
  width: 9px;
  height: 11px;
  background-position: -208px -154px;
}

